import "./App.css";
import { RedocStandalone, SideNavStyleEnum } from "redoc";
import spec from "./message-api-spec.json";

function App() {
  return (
    <RedocStandalone
      spec={spec}
      options={{
        hideHostname: true,
        pathInMiddlePanel: true,
        hideDownloadButton: true,
        theme: { colors: { primary: { main: "#3858E0" } } },
        sideNavStyle: SideNavStyleEnum.SummaryOnly,
        menuToggle: true,
        hideLoading: true,
      }}
    />
  );
}

export default App;
